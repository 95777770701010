const login = "/account/login";
const forgotPassword = "/account/forget-password";
const verifyToken = (token: string) => `/account/verify/${token}`;
const verifyFirstLogin = (token: string) => `/staff/verify-invitation/${token}`;
const resetPassword = "/account/reset-password";
const firstLogin = "/staff/login";
const logout = "/account/logout";

export const AuthAPI = {
  login,
  forgotPassword,
  verifyToken,
  verifyFirstLogin,
  resetPassword,
  firstLogin,
  logout,
};

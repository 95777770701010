import { Suspense } from "react";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { authState } from "../store/auth";
import { PublicRoute } from "../components/PublicRoute";
import { PrivateRoute } from "../components/PrivateRoute";
import { privateRoutes, publicRoutes } from "./routes";
import { LinearProgress, Box } from "@mui/material";
import { userState } from "../store/user";

const Routes = () => {
  const [auth] = useRecoilState(authState);
  const user = useRecoilValue(userState);

  return (
    <Router>
      <Suspense
        fallback={
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        }
      >
        <Switch>
          {publicRoutes.map(({ Component, ...rest }) => (
            <PublicRoute key={rest.path} {...rest}>
              <Component />
            </PublicRoute>
          ))}

          {privateRoutes.map(({ Component, roles, ...rest }) => (
            <PrivateRoute
              key={rest.path}
              {...rest}
              canAccess={
                roles ? (roles.includes(user?.role) ? true : false) : true
              }
            >
              <Component />
            </PrivateRoute>
          ))}

          <Redirect from="/" to={auth.token ? "/analytics" : "/auth/login"} />
        </Switch>
      </Suspense>
    </Router>
  );
};

export default Routes;

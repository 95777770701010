/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Badge from "@mui/material/Badge";
import { Theme, useTheme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

// Icons
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useRecoilState, useSetRecoilState } from "recoil";
import { authState } from "../../store/auth";
import { userState } from "../../store/user";
import { useTranslation } from "react-i18next";
import { permissions, WinfiEmployeeRoles } from "../../services/roles";
import { matchPath, useHistory, useLocation } from "react-router";
import SettingsIcon from "../../assets/settings.svg";
import ReportsIcon from "../../assets/home.svg";
import CompaniesIcon from "../../assets/companies.svg";
import DoorAccessIcon from "../../assets/doorAccessIcon.svg";
import ReceiptIcon from "../../assets/receipt.svg";
import { notificationsState } from "../../store/notifications";
import { LoadingState } from "../../store/global-loading";
import { useLogoutMutation } from "../../queries/auth/logout";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    active: {
      position: "relative",
      "&::after": {
        content: '""',
        width: "100%",
        height: "4px",
        backgroundColor: theme.palette.secondary.main,
        left: 0,
        position: "absolute",
        bottom: "-8px",
        borderRadius: "5px 5px 0 0",
      },
    },
  })
);

const pages = [
  {
    name: "Home",
    path: "/analytics",
    icon: (
      <img src={ReportsIcon} alt="ReportsIcon" style={{ marginRight: 8 }} />
    ),
  },
  {
    name: "Companies",
    path: "/companies",
    icon: (
      <img src={CompaniesIcon} alt="CompaniesIcon" style={{ marginRight: 8 }} />
    ),
  },
  {
    name: "Billing",
    path: "/billing",
    icon: (
      <img src={ReceiptIcon} alt="ReceiptIcon" style={{ marginRight: 8 }} />
    ),
    roles: permissions.billing,
  },
  {
    name: "Door Access",
    path: "/doorAccess",
    icon: (
      <img
        src={DoorAccessIcon}
        alt="DoorAccessIcon"
        style={{ marginRight: 8 }}
      />
    ),
    roles:permissions.doorAccess
  },
  {
    name: "Settings",
    path: "/settings",
    icon: (
      <img src={SettingsIcon} alt="SettingsIcon" style={{ marginRight: 8 }} />
    ),
  },
];

const settings = ["Logout"];

const TopBar: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const setAuth = useSetRecoilState(authState);
  const [user, setUser] = useRecoilState(userState);

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const [notifications, setNotifications] = useRecoilState(notificationsState);

  const setIsLoading = useSetRecoilState(LoadingState);

  const { mutate: logout, status, error, isLoading } = useLogoutMutation();

  React.useEffect(() => {
    if (status === "success") {
      onLogout();
    } else if (status === "error") {
      onLogout();
    }
  }, [status]);

  React.useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const onLogout = () => {
    setAuth({
      token: null,
      refreshToken: null,
    });
    setUser({});
    localStorage.clear();
  };

  const onSettingOption = (option: string) => {
    handleCloseUserMenu();
    option === "Logout" && logout();
  };

  const isExactMatch = (path: string) =>
    path
      ? !!matchPath(location.pathname, {
          path,
          exact: false,
        })
      : false;

  return (
    <AppBar position="sticky" sx={{ zIndex: theme.zIndex.modal }}>
      <Toolbar
        disableGutters
        sx={{
          minHeight: "58px !important",
          paddingX: "38px",
          justifyContent: "space-between",
        }}
      >
        <IconButton sx={{ p: 0, mr: { md: "200px" } }}>
          <Avatar
            variant="square"
            alt="Logo"
            src={require("../../assets/winfi.png")}
          />
        </IconButton>

        <Box
          sx={{
            flexGrow: 1,
            display: { xs: "flex", md: "none" },
          }}
        >
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>

          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: "block", md: "none" },
            }}
          >
            {pages.map(({ name, path, roles }) =>
              roles ? (
                roles.includes(user?.role) &&
                ((name === "Door Access" &&
                  (user?.role === WinfiEmployeeRoles.superAdmin ||
                    user?.role ===
                      WinfiEmployeeRoles.technicalSupportManager)) ||
                  name !== "Door Access") ? (
                  <MenuItem key={name} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">{name}</Typography>
                  </MenuItem>
                ) : (
                  <></>
                )
              ) : (
                <MenuItem key={name} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{name}</Typography>
                </MenuItem>
              )
            )}
          </Menu>
        </Box>

        <Box sx={{ display: { xs: "none", md: "flex" } }}>
          {pages.map(({ name, path, icon, roles }) =>
            roles ? (
              roles.includes(user?.role) ? (
                <Button
                  key={name}
                  onClick={() => history.push(path)}
                  className={isExactMatch(path) ? classes.active : ""}
                  sx={{
                    my: 2,
                    color: "white",
                    fontWeight: isExactMatch(path)
                      ? "fontWeightMedium"
                      : "fontWeightRegular",
                    display: "flex",
                    marginX: "8px",
                    marginY: "8px",
                  }}
                >
                  {icon}
                  {name}
                </Button>
              ) : (
                <></>
              )
            ) : (
              <Button
                key={name}
                onClick={() => history.push(path)}
                className={isExactMatch(path) ? classes.active : ""}
                sx={{
                  my: 2,
                  color: "white",
                  fontWeight: isExactMatch(path)
                    ? "fontWeightMedium"
                    : "fontWeightRegular",
                  display: "flex",
                  marginX: "8px",
                  marginY: "8px",
                }}
              >
                {icon}
                {name}
              </Button>
            )
          )}
        </Box>

        <Box sx={{ flexGrow: 0 }}>
          <IconButton size="large" aria-label="" color="inherit">
            <Badge color="error">
              <NotificationsNoneOutlinedIcon />
            </Badge>
          </IconButton>

          {/* <IconButton
            className={isExactMatch("/settings") ? classes.active : ""}
            size="large"
            aria-label="Settings"
            color="inherit"
            sx={{
              marginY: "8px",
            }}
            onClick={() => {
              history.push("/settings");
            }}
          >
            <SettingsOutlinedIcon />
          </IconButton> */}

          <Button
            endIcon={<ArrowDropDownIcon style={{ color: "#fff" }} />}
            sx={{ textAlign: "left", alignItems: "flex-start" }}
            onClick={handleOpenUserMenu}
          >
            <Box>
              <Typography
                display="block"
                style={{ color: "#fff", fontWeight: 300, fontSize: "14px" }}
              >
                {user.firstName} {user.lastName}
              </Typography>

              <Typography
                style={{
                  color: "rgba(255, 255, 255, 0.7)",
                  fontWeight: 300,
                  fontSize: "14px",
                }}
              >
                {t(`teamMembers.${user.role as WinfiEmployeeRoles}`)}
              </Typography>
            </Box>
          </Button>

          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting) => (
              <MenuItem key={setting} onClick={() => onSettingOption(setting)}>
                <Typography textAlign="center">{setting}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;

const locales = {
  firstName: "First name",
  lastName: "Last name",
  countryCode: "Country code",
  phoneNumber: "Phone number",
  emailAddress: "Email address",
  selectRole: "Select role",
  superAdmin: "Super admin",
  salesManager: "Sales manager",
  technicalSupport: "Technical support",
  technicalSupportManager: "Technical support manager",
  accountManager: "Account manager",
  accountManagementManager: "Account management manager",
  reportingRole: "Reporting role",
  sales: "Sales",
  inputIsRequierd: "{{inputName}} is requierd",
  role: "Role",
  errorInviteMembers: "Error invite members",
  invitedSuccess: "{{invitaionsLength}} new invitations sent successfully",
  inviteTitle: "Invite team members via email",
  inviteSubtitle: "Anyone you will add will recieve an invitation email.",
  addAnotherMember: "Add another member",
  inputMustBeLettersOnly: "{{inputName}} must contain only letters",
  inputMustBeNumbersOnly: "{{inputName}} must contain only numbers",
  minLengthIs: "Minmum length is 3 letters",
  numbersMinLengthIs: "Minmum length is 3 numbers",
  name: "Name",
  lastActivityDate: "Last activity date",
  action: "Action",
  status: "Status",
  owner: "Owner",
  reSend: "Re-send",
  cancel: "Cancel",
  errorFetchingMembers: "Error Fetching Members",
  noPendingInvitations: "No pending invitations at the moment",
  noExitingMembers: "no exiting members at the moment",
  emptyPendingsHelperText: "You’ll see a list of all your sent invitaions here",
  emptyExistingsHelperText: "You’ll see a list of all your members here",
  editTeamMemberDetails: "Edit team member details",
  baiscInfo: "Baisc info",
  workInfo: "Work info",
  deleteMember: "Delete member",
  save: "Save",
  active: "Active",
  blocked: "Blocked",
  staffUpdatedSuccessfully: "Staff updated successfully",
  exitEditBeforSavingBody:
    "Are you sure you want to exit without saving changes? Please note that discarded changes can’t be restored.",
  exitEditBeforSavingTitle: "Exit without saving",
  deleteTeamMember: "Delete team member",
  araYouSureYouWantToDeleteMember:
    "Are you sure you want to permanently delete <strong>{{member}}</strong> from your team? Please note that this action can’t be reverted.",
  staffDeletedSuccessfully: "Staff deleted successfully",
  araYouSureYouWantToCancelInvitation:
    "Are you sure you want to cancel invitation to  <strong>{{member}}</strong> ?",
  cancelInvitaion: "Cancel invitation",

  selectBranch: "Select branch",
  IT: "IT",
  marketing: "Marketing",
  operator: "Operator",
  analytics: "Analytics",
  branch: "Branch",
};

export default locales;

import React from "react";
import {
  Route,
  Redirect,
  RouteComponentProps,
  RouteChildrenProps,
} from "react-router-dom";
import { useRecoilState } from "recoil";
import { authState } from "../../store/auth";
// Components
import TopBar from "../../components/TopBar";
import { Box } from "@mui/material";
interface IProps {
  component?:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
  render?: (props: RouteComponentProps<any>) => React.ReactNode;
  children?:
    | ((props: RouteChildrenProps<any>) => React.ReactNode)
    | React.ReactNode;
  path?: string | string[];
  exact?: boolean;
  sensitive?: boolean;
  strict?: boolean;
  canAccess?: boolean;
}

export const PrivateRoute: React.FC<IProps> = ({
  children,
  canAccess,
  ...rest
}) => {
  const [auth] = useRecoilState(authState);
  return (
    <>
      <TopBar />
      <Box display="flex" justifyContent="flex-start" overflow="auto">
        <Box
          style={{
            backgroundColor: "#fafbfc",
            width: "100%",
            height: "calc(100vh - 58px)",
          }}
        >
          <Route
            {...rest}
            render={({ location }) =>
              auth.token && canAccess ? (
                children
              ) : (
                <Redirect
                  to={{
                    pathname: "/auth/login",
                    state: { from: location },
                  }}
                />
              )
            }
          />
        </Box>
      </Box>
    </>
  );
};
